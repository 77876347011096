import React, { Component } from "react";
import { TimelineLite, Back, Power2 } from "gsap";
export default class LoadingScreen extends Component {
  componentDidMount() {
    const timing = 2;
    const easing = Back.easeOut.config(1.7);
    let tl = new TimelineLite({ onComplete: this.props.disableLoadingScreen });
    tl
    

      .fromTo(
        "#logo",
        timing/2,
        { ease: Power2.easeOut, scale:0,opacity:0, x: 150, transformOrigin: "50% 50%"  },
        { ease: Power2.easeOut, scale:1,opacity:1},
        `${timing}+=.5`
      )
      .to(
        "#logo",
        timing,      
        { ease: easing, x: 0 },
        'logo'
      )
        .fromTo("#mask", timing, { x: 150 }, { x: 0 }, `logo-=`)
      .to("#mask", timing, { ease: easing, width: 136, borderRadius: "50%" },`logo-=`)
      .fromTo(
        "#napis",
        timing,
        { ease: easing, x: -50 },
        { ease: easing, x: 0 },
        `logo-=`
      )
      .to(".loading-screen", timing, { ease: easing, top: "-100%" });
  }

  render() {
    return (
      <div className="loading-screen">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width="575px"
          height="127px"
          viewBox="0 0 575 127"
          xmlSpace="preserve"
        >
          <g id="napis">
            <path
              fill="#0064A3"
              d="M241.296,31.001c0.608,0,1.182,0.119,1.723,0.354c0.54,0.237,1.022,0.507,1.444,0.812
		c0.422,0.304,0.786,0.607,1.09,0.912c0.304,0.304,0.523,0.524,0.659,0.658l10.591,14.038c0.135,0.101,0.203,0.304,0.203,0.608
		c0,0.271-0.101,0.507-0.304,0.71c-0.237,0.202-0.507,0.304-0.811,0.304h-7.906c-0.878,0-1.554-0.321-2.026-0.963
		c-1.589-2.06-3.025-3.919-4.308-5.575c-0.778-0.979-1.402-1.773-1.875-2.381c-0.474-0.608-0.913-1.174-1.318-1.698
		s-0.752-0.971-1.039-1.343s-0.465-0.608-0.532-0.709c-0.135-0.068-0.22-0.102-0.253-0.102c-0.17,0-0.254,0.102-0.254,0.304v11.251
		c0,0.372-0.119,0.667-0.355,0.886c-0.236,0.221-0.54,0.33-0.912,0.33h-6.74c-0.338,0-0.633-0.109-0.887-0.33
		c-0.253-0.219-0.379-0.514-0.379-0.886V18.484c0-1.114,0.405-2.119,1.215-3.015c0.811-0.895,1.858-1.342,3.142-1.342h3.649
		c0.372,0,0.676,0.118,0.912,0.354c0.236,0.237,0.355,0.541,0.355,0.912V25.63c0,0.169,0.084,0.253,0.254,0.253
		c0.101,0,0.185-0.033,0.253-0.101l8.412-10.693c0.406-0.641,1.065-0.962,1.977-0.962h7.703c0.27,0,0.524,0.101,0.76,0.303
		c0.203,0.203,0.304,0.44,0.304,0.71c0,0.338-0.068,0.541-0.203,0.608c-1.723,2.264-3.277,4.291-4.662,6.081
		c-0.845,1.082-1.504,1.944-1.976,2.585c-0.474,0.642-0.921,1.234-1.343,1.774c-0.423,0.541-0.778,0.988-1.064,1.342
		c-0.288,0.355-0.449,0.55-0.482,0.583c-0.068,0.102-0.237,0.313-0.507,0.634c-0.27,0.321-0.625,0.651-1.064,0.988
		c-0.439,0.338-0.955,0.634-1.546,0.887C242.605,30.875,241.971,31.001,241.296,31.001"
            />
            <path
              fill="#0064A3"
              d="M273.648,50.107c-4.798,0-8.582-1.166-11.352-3.497c-2.771-2.332-4.24-5.76-4.409-10.288
		c-0.034-0.945-0.051-2.398-0.051-4.358c0-1.959,0.017-3.429,0.051-4.409c0.135-4.46,1.613-7.897,4.434-10.313
		c2.821-2.415,6.597-3.623,11.327-3.623c4.696,0,8.455,1.208,11.276,3.623c2.82,2.416,4.299,5.853,4.435,10.313
		c0.066,1.96,0.102,3.429,0.102,4.409c0,1.014-0.035,2.466-0.102,4.358c-0.169,4.528-1.63,7.956-4.383,10.288
		C282.221,48.941,278.445,50.107,273.648,50.107 M273.648,42.505c1.756,0,3.159-0.532,4.206-1.597
		c1.047-1.064,1.605-2.694,1.672-4.89c0.068-1.959,0.102-3.361,0.102-4.206c0-0.844-0.034-2.213-0.102-4.105
		c-0.067-2.196-0.625-3.826-1.672-4.89s-2.45-1.597-4.206-1.597c-1.791,0-3.21,0.533-4.257,1.597
		c-1.048,1.064-1.605,2.694-1.672,4.89c-0.034,0.946-0.051,2.315-0.051,4.105c0,1.824,0.017,3.227,0.051,4.206
		c0.067,2.196,0.624,3.826,1.672,4.89C270.438,41.974,271.857,42.505,273.648,42.505"
            />
            <path
              fill="#0064A3"
              d="M294.431,48.181V18.535c0-1.183,0.422-2.213,1.268-3.092c0.844-0.878,1.892-1.317,3.142-1.317h1.673
		c0.371,0,0.812,0.076,1.317,0.228c0.507,0.152,1.029,0.6,1.57,1.343c1.993,3.109,3.783,5.912,5.372,8.412
		c0.979,1.52,1.748,2.729,2.307,3.624c0.557,0.895,1.089,1.722,1.596,2.483c0.507,0.76,0.929,1.411,1.267,1.951
		c0.338,0.541,0.54,0.861,0.608,0.963c0,0.068,0.068,0.101,0.203,0.101c0.169,0,0.253-0.084,0.253-0.253V18.535
		c0-1.318,0.456-2.382,1.369-3.193c0.912-0.811,1.941-1.216,3.091-1.216h3.243c0.372,0,0.676,0.118,0.912,0.354
		c0.235,0.305,0.354,0.626,0.354,0.963V48.13c0,0.304-0.119,0.609-0.354,0.912c-0.271,0.271-0.574,0.406-0.912,0.406h-5.727
		c-0.744,0-1.317-0.321-1.723-0.963c-2.027-3.108-3.852-5.895-5.474-8.362c-0.98-1.486-1.757-2.677-2.331-3.572
		c-0.575-0.895-1.114-1.724-1.621-2.484c-0.507-0.76-0.931-1.401-1.268-1.926c-0.339-0.523-0.541-0.835-0.608-0.937
		c-0.102-0.102-0.187-0.152-0.253-0.152c-0.17,0-0.253,0.085-0.253,0.254v16.875c0,0.372-0.137,0.676-0.406,0.912
		c-0.271,0.237-0.574,0.355-0.912,0.355h-6.436c-0.305,0-0.592-0.135-0.861-0.406C294.566,48.772,294.431,48.485,294.431,48.181"
            />
            <path
              fill="#0064A3"
              d="M356.694,14.126c0.372,0,0.684,0.126,0.937,0.38c0.254,0.253,0.381,0.549,0.381,0.887v5.675
		c0,0.373-0.127,0.677-0.381,0.913c-0.253,0.237-0.564,0.354-0.937,0.354h-11.758c0.742,0,1.41,0.271,2.002,0.812
		c0.591,0.54,0.887,1.148,0.887,1.824v23.21c0,0.372-0.136,0.659-0.405,0.862c-0.237,0.236-0.541,0.354-0.912,0.354h-7.044
		c-0.373,0-0.677-0.118-0.913-0.354c-0.271-0.203-0.405-0.49-0.405-0.862V23.856c0-0.473-0.151-0.844-0.456-1.115
		c-0.304-0.27-0.658-0.406-1.063-0.406h-7.349c-0.339,0-0.643-0.117-0.912-0.354c-0.271-0.236-0.405-0.54-0.405-0.913v-2.584
		c0-0.641,0.127-1.233,0.38-1.774c0.253-0.54,0.591-1.004,1.013-1.393c0.424-0.388,0.896-0.685,1.42-0.887
		c0.523-0.203,1.056-0.304,1.597-0.304H356.694z"
            />
            <path
              fill="#0064A3"
              d="M391.033,49.499h-7.146c-0.946,0-1.622-0.439-2.027-1.318l-5.169-10.136c-0.304-0.54-0.76-0.81-1.369-0.81
		h-6.638c0.844,0,1.511,0.296,2.002,0.887c0.489,0.591,0.735,1.191,0.735,1.799v8.362c0,0.372-0.12,0.658-0.355,0.861
		c-0.237,0.237-0.541,0.355-0.913,0.355h-6.942c-0.338,0-0.634-0.127-0.887-0.38c-0.254-0.254-0.381-0.533-0.381-0.836V18.585
		c0-1.249,0.439-2.288,1.318-3.116c0.878-0.828,1.908-1.242,3.092-1.242h10.743c1.892,0,3.69,0.203,5.396,0.608
		c1.706,0.406,3.21,1.064,4.512,1.977c1.3,0.912,2.339,2.103,3.115,3.572c0.777,1.47,1.166,3.269,1.166,5.398
		c0,2.027-0.456,3.851-1.368,5.473c-0.912,1.622-2.264,2.939-4.055,3.953c-0.27,0.169-0.414,0.372-0.43,0.608
		c-0.019,0.237,0.024,0.423,0.126,0.557l6.386,11.555c0.102,0.202,0.152,0.388,0.152,0.557c0,0.271-0.11,0.507-0.329,0.71
		C391.547,49.398,391.303,49.499,391.033,49.499 M381.505,25.731c0-1.385-0.414-2.44-1.241-3.167
		c-0.829-0.727-1.901-1.09-3.218-1.09h-8.718c0.845,0,1.521,0.287,2.027,0.861c0.507,0.575,0.76,1.184,0.76,1.825v4.105
		c0,0.405,0.144,0.76,0.432,1.064c0.286,0.304,0.649,0.456,1.09,0.456h4.409c1.316,0,2.389-0.329,3.218-0.988
		C381.091,28.138,381.505,27.117,381.505,25.731"
            />
            <path
              fill="#0064A3"
              d="M411.125,50.107c-4.798,0-8.582-1.166-11.352-3.497c-2.771-2.332-4.24-5.76-4.409-10.288
		c-0.034-0.945-0.051-2.398-0.051-4.358c0-1.959,0.017-3.429,0.051-4.409c0.135-4.46,1.613-7.897,4.434-10.313
		c2.821-2.415,6.598-3.623,11.327-3.623c4.696,0,8.455,1.208,11.276,3.623c2.819,2.416,4.299,5.853,4.434,10.313
		c0.067,1.96,0.103,3.429,0.103,4.409c0,1.014-0.035,2.466-0.103,4.358c-0.169,4.528-1.63,7.956-4.383,10.288
		C419.698,48.941,415.922,50.107,411.125,50.107 M411.125,42.505c1.756,0,3.159-0.532,4.206-1.597
		c1.047-1.064,1.605-2.694,1.672-4.89c0.068-1.959,0.103-3.361,0.103-4.206c0-0.844-0.034-2.213-0.103-4.105
		c-0.066-2.196-0.625-3.826-1.672-4.89s-2.45-1.597-4.206-1.597c-1.791,0-3.21,0.533-4.257,1.597
		c-1.048,1.064-1.605,2.694-1.672,4.89c-0.034,0.946-0.051,2.315-0.051,4.105c0,1.824,0.017,3.227,0.051,4.206
		c0.066,2.196,0.624,3.826,1.672,4.89C407.916,41.974,409.334,42.505,411.125,42.505"
            />
            <path
              fill="#0064A3"
              d="M431.934,48.181V18.484c0-0.641,0.127-1.233,0.38-1.774c0.253-0.54,0.591-0.996,1.014-1.368
		c0.422-0.371,0.895-0.667,1.419-0.887c0.523-0.219,1.055-0.329,1.596-0.329h3.953c0.371,0,0.659,0.118,0.862,0.354
		c0.235,0.237,0.354,0.541,0.354,0.912v24.579c0,0.271,0.136,0.601,0.405,0.988c0.271,0.389,0.66,0.583,1.166,0.583h13.582
		c0.371,0,0.684,0.11,0.938,0.329c0.254,0.221,0.38,0.533,0.38,0.938v5.372c0,0.405-0.126,0.718-0.38,0.937
		c-0.254,0.221-0.566,0.33-0.938,0.33h-23.464c-0.304,0-0.592-0.135-0.862-0.406C432.069,48.772,431.934,48.485,431.934,48.181"
            />
            <path
              fill="#0064A3"
              d="M460.704,49.296c-0.203-0.27-0.305-0.523-0.305-0.76c0-0.236,0.017-0.388,0.052-0.456l11.757-32.332
		c0.371-0.98,1.047-1.47,2.026-1.47h7.855c0.98,0,1.655,0.49,2.027,1.47l11.757,32.332c0.034,0.068,0.051,0.22,0.051,0.456
		c0,0.271-0.11,0.516-0.329,0.735c-0.221,0.22-0.465,0.329-0.735,0.329h-6.537c-0.846,0-1.419-0.371-1.724-1.114l-2.635-7.146
		c-1.216,0.473-2.45,0.861-3.7,1.166c-1.25,0.303-2.458,0.531-3.623,0.684c-1.166,0.152-2.255,0.253-3.269,0.304
		c-1.014,0.05-1.893,0.076-2.635,0.076h-1.191c-0.321,0-0.566-0.017-0.735-0.051c0.609,0.101,1.107,0.439,1.495,1.013
		c0.389,0.576,0.432,1.251,0.127,2.028l-0.71,1.926C469.42,49.229,468.845,49.6,468,49.6h-6.537
		C461.226,49.6,460.973,49.499,460.704,49.296 M473.576,36.323c0.944-0.067,1.926-0.186,2.938-0.355
		c0.878-0.135,1.799-0.33,2.762-0.582c0.964-0.254,1.884-0.617,2.763-1.09l-3.902-11.149L473.576,36.323z"
            />
            <path
              fill="#0064A3"
              d="M245.624,80.635c0.757,0,1.473,0.148,2.146,0.442s1.272,0.631,1.799,1.01
		c0.525,0.378,0.978,0.757,1.357,1.135c0.379,0.379,0.652,0.653,0.82,0.821l13.191,17.483c0.168,0.126,0.253,0.379,0.253,0.757
		c0,0.338-0.127,0.631-0.379,0.884c-0.295,0.252-0.631,0.379-1.01,0.379h-9.845c-1.095,0-1.936-0.4-2.525-1.2
		c-1.978-2.564-3.766-4.88-5.365-6.941c-0.968-1.221-1.747-2.209-2.336-2.968c-0.589-0.757-1.135-1.461-1.64-2.113
		c-0.505-0.652-0.937-1.209-1.294-1.672c-0.358-0.464-0.579-0.759-0.663-0.885c-0.169-0.084-0.274-0.126-0.316-0.126
		c-0.211,0-0.315,0.126-0.315,0.378v14.013c0,0.463-0.148,0.831-0.442,1.104c-0.295,0.274-0.673,0.411-1.136,0.411h-8.394
		c-0.421,0-0.789-0.137-1.105-0.411c-0.315-0.272-0.473-0.641-0.473-1.104V65.046c0-1.389,0.505-2.64,1.515-3.756
		c1.01-1.114,2.313-1.673,3.913-1.673h4.544c0.463,0,0.841,0.148,1.136,0.442c0.294,0.295,0.442,0.674,0.442,1.137v12.75
		c0,0.211,0.104,0.314,0.315,0.314c0.127,0,0.231-0.041,0.316-0.126l10.477-13.317c0.505-0.799,1.326-1.2,2.462-1.2h9.593
		c0.336,0,0.652,0.127,0.947,0.379c0.252,0.253,0.379,0.548,0.379,0.884c0,0.421-0.085,0.673-0.253,0.757
		c-2.146,2.82-4.082,5.344-5.806,7.574c-1.053,1.347-1.873,2.42-2.462,3.219c-0.59,0.8-1.147,1.537-1.673,2.209
		c-0.526,0.674-0.968,1.231-1.325,1.673c-0.358,0.441-0.558,0.685-0.599,0.726c-0.085,0.127-0.295,0.39-0.632,0.789
		c-0.337,0.4-0.779,0.811-1.325,1.23c-0.547,0.421-1.189,0.789-1.925,1.104C247.254,80.477,246.465,80.635,245.624,80.635"
            />
            <path
              fill="#0064A3"
              d="M269.614,102.031V65.046c0-0.799,0.158-1.536,0.474-2.209c0.315-0.673,0.735-1.241,1.262-1.704
		c0.525-0.463,1.114-0.831,1.767-1.105c0.652-0.273,1.314-0.411,1.988-0.411h4.923c0.462,0,0.82,0.148,1.073,0.442
		c0.294,0.295,0.442,0.674,0.442,1.137v30.61c0,0.337,0.168,0.748,0.505,1.231c0.336,0.483,0.82,0.726,1.451,0.726h16.915
		c0.463,0,0.852,0.138,1.168,0.41c0.314,0.274,0.473,0.663,0.473,1.168v6.69c0,0.505-0.158,0.895-0.473,1.167
		c-0.316,0.274-0.705,0.411-1.168,0.411h-29.222c-0.379,0-0.737-0.168-1.073-0.506C269.782,102.767,269.614,102.409,269.614,102.031
		"
            />
            <path
              fill="#0064A3"
              d="M306.599,102.094V65.172c0-1.599,0.546-2.913,1.641-3.945c1.094-1.03,2.378-1.546,3.851-1.546h5.049
		c0.505,0,0.884,0.148,1.136,0.441c0.294,0.295,0.442,0.674,0.442,1.137v40.835c0,0.421-0.148,0.779-0.442,1.073
		c-0.252,0.337-0.631,0.505-1.136,0.505h-8.962c-0.421,0-0.789-0.158-1.105-0.474C306.757,102.883,306.599,102.515,306.599,102.094"
            />
            <path
              fill="#0064A3"
              d="M368.167,59.87c0.463,0,0.841,0.137,1.136,0.411c0.294,0.274,0.441,0.662,0.441,1.167v40.836
		c0,0.463-0.147,0.819-0.441,1.071c-0.295,0.295-0.673,0.442-1.136,0.442h-8.016c-0.422,0-0.789-0.157-1.104-0.473
		c-0.316-0.316-0.474-0.663-0.474-1.041V80.698c0-0.21-0.105-0.315-0.315-0.315c-0.126,0-0.232,0.042-0.316,0.126l-6.122,11.298
		c-0.547,0.968-1.283,1.451-2.209,1.451h-3.408c-0.883,0-1.619-0.483-2.209-1.451l-6.059-11.234
		c-0.085-0.126-0.189-0.189-0.315-0.189c-0.211,0-0.315,0.105-0.315,0.315v21.586c0,0.42-0.169,0.777-0.505,1.071
		c-0.295,0.295-0.675,0.442-1.137,0.442h-7.952c-0.464,0-0.843-0.147-1.137-0.442c-0.294-0.252-0.44-0.608-0.44-1.071V65.108
		c0-0.884,0.188-1.662,0.567-2.335c0.379-0.672,0.842-1.219,1.389-1.64s1.114-0.736,1.704-0.947c0.589-0.21,1.094-0.316,1.515-0.316
		h3.534c1.011,0,1.768,0.442,2.272,1.326l10.478,18.871c0.041,0.126,0.146,0.189,0.314,0.189c0.127,0,0.231-0.063,0.316-0.189
		l10.54-18.871c0.505-0.884,1.262-1.326,2.271-1.326H368.167z"
            />
            <path
              fill="#0064A3"
              d="M374.403,103.419c-0.253-0.336-0.379-0.652-0.379-0.946s0.021-0.482,0.063-0.567l14.643-40.269
		c0.462-1.22,1.304-1.83,2.524-1.83h9.783c1.22,0,2.061,0.61,2.523,1.83l14.645,40.269c0.041,0.085,0.062,0.273,0.062,0.567
		c0,0.337-0.137,0.643-0.41,0.915c-0.273,0.274-0.579,0.41-0.915,0.41H408.8c-1.052,0-1.768-0.462-2.146-1.389l-3.281-8.898
		c-1.516,0.589-3.052,1.072-4.607,1.451c-1.557,0.379-3.061,0.663-4.513,0.852c-1.451,0.189-2.809,0.316-4.071,0.38
		c-1.262,0.063-2.357,0.094-3.282,0.094h-1.482c-0.4,0-0.705-0.021-0.915-0.063c0.757,0.126,1.378,0.548,1.861,1.264
		c0.483,0.715,0.536,1.557,0.157,2.523l-0.883,2.398c-0.379,0.927-1.095,1.389-2.146,1.389h-8.141
		C375.055,103.798,374.739,103.672,374.403,103.419 M390.434,87.262c1.177-0.084,2.398-0.23,3.661-0.441
		c1.093-0.167,2.24-0.41,3.438-0.726c1.199-0.315,2.346-0.768,3.44-1.356l-4.86-13.887L390.434,87.262z"
            />
            <path
              fill="#0064A3"
              d="M450.752,59.618c0.462,0,0.852,0.158,1.167,0.474c0.315,0.315,0.474,0.684,0.474,1.104v7.069
		c0,0.463-0.158,0.842-0.474,1.136c-0.315,0.295-0.705,0.442-1.167,0.442h-14.644c0.925,0,1.757,0.337,2.493,1.01
		s1.104,1.431,1.104,2.271v28.907c0,0.463-0.169,0.82-0.505,1.072c-0.295,0.295-0.674,0.442-1.136,0.442h-8.772
		c-0.465,0-0.843-0.147-1.137-0.442c-0.338-0.252-0.505-0.609-0.505-1.072V71.735c0-0.589-0.189-1.051-0.567-1.388
		c-0.38-0.336-0.821-0.505-1.326-0.505h-9.152c-0.42,0-0.799-0.147-1.135-0.442c-0.338-0.294-0.505-0.673-0.505-1.136v-3.219
		c0-0.799,0.158-1.536,0.473-2.209c0.315-0.673,0.736-1.251,1.262-1.736c0.526-0.483,1.115-0.852,1.769-1.104
		c0.651-0.253,1.313-0.379,1.987-0.379H450.752z"
            />
            <path
              fill="#0064A3"
              d="M456.899,87.072V65.234c0-0.883,0.178-1.662,0.536-2.335c0.357-0.673,0.799-1.241,1.326-1.704
		c0.524-0.462,1.114-0.81,1.767-1.041s1.272-0.348,1.862-0.348h4.734c0.462,0,0.852,0.148,1.167,0.442
		c0.315,0.295,0.473,0.674,0.473,1.137v25.561c0,2.398,0.557,4.292,1.673,5.68c1.114,1.39,2.935,2.083,5.46,2.083
		c2.524,0,4.343-0.693,5.459-2.083c1.114-1.388,1.672-3.281,1.672-5.68V65.172c0-0.883,0.179-1.662,0.537-2.335
		c0.357-0.673,0.811-1.241,1.357-1.704c0.546-0.463,1.136-0.799,1.767-1.01c0.631-0.21,1.241-0.316,1.831-0.316h4.733
		c0.462,0,0.852,0.148,1.167,0.442c0.315,0.295,0.474,0.674,0.474,1.137v25.687c0,2.988-0.453,5.565-1.357,7.732
		s-2.178,3.955-3.817,5.365c-1.642,1.409-3.63,2.461-5.965,3.154c-2.335,0.694-4.935,1.041-7.794,1.041
		c-2.82,0-5.408-0.336-7.765-1.009s-4.365-1.714-6.026-3.124c-1.663-1.41-2.957-3.208-3.883-5.397
		C457.361,92.648,456.899,90.061,456.899,87.072"
            />
            <path
              fill="#E8583F"
              d="M232.948,116.891h-2.43v4.184h-1.371V110.73h3.424c1.165,0,2.062,0.265,2.689,0.795
		c0.628,0.53,0.942,1.303,0.942,2.315c0,0.646-0.174,1.206-0.522,1.685c-0.349,0.479-0.833,0.836-1.454,1.073l2.431,4.391v0.085
		h-1.464L232.948,116.891z M230.518,115.774h2.096c0.677,0,1.216-0.175,1.616-0.526c0.4-0.351,0.6-0.822,0.6-1.41
		c0-0.641-0.19-1.131-0.571-1.473c-0.382-0.342-0.932-0.515-1.652-0.521h-2.089V115.774z"
            />
            <path
              fill="#E8583F"
              d="M241.097,121.217c-1.042,0-1.89-0.343-2.543-1.026c-0.654-0.685-0.981-1.601-0.981-2.746v-0.242
		c0-0.762,0.146-1.443,0.437-2.042c0.292-0.6,0.699-1.068,1.222-1.407c0.524-0.339,1.091-0.508,1.702-0.508
		c0.999,0,1.776,0.33,2.33,0.988c0.554,0.657,0.832,1.601,0.832,2.826v0.548h-5.208c0.019,0.758,0.24,1.369,0.664,1.837
		c0.424,0.466,0.963,0.699,1.616,0.699c0.464,0,0.857-0.095,1.18-0.283c0.322-0.19,0.604-0.441,0.845-0.755l0.803,0.627
		C243.352,120.721,242.386,121.217,241.097,121.217 M240.934,114.325c-0.531,0-0.976,0.192-1.336,0.579
		c-0.36,0.386-0.582,0.927-0.668,1.624h3.851v-0.101c-0.038-0.668-0.218-1.186-0.54-1.552
		C241.918,114.509,241.482,114.325,240.934,114.325"
            />
            <polygon
              fill="#E8583F"
              points="247.761,117.515 246.937,118.375 246.937,121.075 245.623,121.075 245.623,110.161 
		246.937,110.161 246.937,116.762 247.64,115.916 250.035,113.387 251.633,113.387 248.642,116.598 251.981,121.075 250.44,121.075 
			"
            />
            <path
              fill="#E8583F"
              d="M257.736,120.314c-0.512,0.602-1.262,0.901-2.252,0.901c-0.82,0-1.444-0.236-1.873-0.714
		c-0.428-0.476-0.645-1.18-0.649-2.113v-5.002h1.314v4.967c0,1.165,0.474,1.748,1.421,1.748c1.004,0,1.672-0.374,2.003-1.122v-5.593
		h1.315v7.688h-1.251L257.736,120.314z"
            />
            <path
              fill="#E8583F"
              d="M267.505,117.315c0,1.17-0.267,2.113-0.802,2.828c-0.535,0.715-1.26,1.073-2.174,1.073
		c-0.933,0-1.668-0.296-2.203-0.888v3.7h-1.314v-10.643h1.2l0.064,0.853c0.536-0.662,1.279-0.994,2.231-0.994
		c0.924,0,1.655,0.348,2.192,1.045c0.538,0.695,0.806,1.665,0.806,2.904V117.315z M266.191,117.167c0-0.867-0.185-1.551-0.554-2.054
		c-0.369-0.502-0.876-0.753-1.52-0.753c-0.796,0-1.393,0.353-1.791,1.059v3.673c0.393,0.701,0.995,1.052,1.805,1.052
		c0.63,0,1.131-0.25,1.502-0.749C266.005,118.895,266.191,118.151,266.191,117.167"
            />
            <path
              fill="#E8583F"
              d="M272.365,121.217c-1.042,0-1.89-0.343-2.543-1.026c-0.654-0.685-0.981-1.601-0.981-2.746v-0.242
		c0-0.762,0.146-1.443,0.437-2.042c0.292-0.6,0.699-1.068,1.222-1.407c0.524-0.339,1.091-0.508,1.702-0.508
		c0.999,0,1.776,0.33,2.33,0.988c0.554,0.657,0.832,1.601,0.832,2.826v0.548h-5.208c0.019,0.758,0.24,1.369,0.664,1.837
		c0.424,0.466,0.963,0.699,1.616,0.699c0.464,0,0.857-0.095,1.18-0.283c0.322-0.19,0.604-0.441,0.845-0.755l0.803,0.627
		C274.62,120.721,273.654,121.217,272.365,121.217 M272.202,114.325c-0.531,0-0.976,0.192-1.336,0.579
		c-0.36,0.386-0.582,0.927-0.668,1.624h3.851v-0.101c-0.038-0.668-0.218-1.186-0.54-1.552
		C273.187,114.509,272.751,114.325,272.202,114.325"
            />
            <path
              fill="#E8583F"
              d="M280.6,114.566c-0.199-0.033-0.415-0.05-0.646-0.05c-0.863,0-1.448,0.367-1.755,1.101v5.457h-1.315v-7.688
		h1.279l0.021,0.888c0.431-0.686,1.042-1.029,1.833-1.029c0.256,0,0.45,0.032,0.583,0.1V114.566z"
            />
            <path
              fill="#E8583F"
              d="M286.27,121.074c-0.076-0.15-0.138-0.421-0.184-0.81c-0.612,0.635-1.341,0.952-2.189,0.952
		c-0.758,0-1.379-0.214-1.865-0.644c-0.485-0.429-0.728-0.972-0.728-1.63c0-0.801,0.304-1.423,0.913-1.866
		c0.608-0.442,1.464-0.664,2.568-0.664h1.279v-0.604c0-0.459-0.138-0.825-0.412-1.097c-0.275-0.273-0.68-0.409-1.215-0.409
		c-0.469,0-0.862,0.119-1.18,0.354c-0.317,0.237-0.475,0.524-0.475,0.86h-1.322c0-0.383,0.136-0.754,0.409-1.112
		c0.272-0.356,0.641-0.641,1.108-0.849c0.466-0.208,0.979-0.313,1.538-0.313c0.886,0,1.58,0.223,2.082,0.665
		c0.502,0.443,0.763,1.054,0.782,1.829v3.539c0,0.705,0.089,1.268,0.27,1.683v0.114H286.27z M284.088,120.072
		c0.412,0,0.803-0.105,1.172-0.318c0.37-0.215,0.637-0.491,0.803-0.832v-1.577h-1.03c-1.611,0-2.416,0.471-2.416,1.414
		c0,0.412,0.138,0.733,0.412,0.966C283.304,119.956,283.657,120.072,284.088,120.072"
            />
            <path
              fill="#E8583F"
              d="M292.522,120.144c0.469,0,0.878-0.142,1.229-0.426s0.545-0.64,0.583-1.066h1.243
		c-0.024,0.44-0.175,0.86-0.454,1.259c-0.28,0.397-0.653,0.715-1.12,0.95c-0.466,0.237-0.96,0.356-1.48,0.356
		c-1.048,0-1.88-0.349-2.497-1.048c-0.618-0.699-0.928-1.654-0.928-2.867v-0.22c0-0.749,0.138-1.414,0.412-1.997
		c0.274-0.582,0.669-1.035,1.183-1.357c0.515-0.322,1.122-0.482,1.822-0.482c0.862,0,1.578,0.258,2.149,0.773
		c0.571,0.518,0.875,1.188,0.913,2.011h-1.243c-0.038-0.496-0.227-0.905-0.565-1.225c-0.339-0.32-0.757-0.479-1.254-0.479
		c-0.668,0-1.185,0.239-1.553,0.721c-0.367,0.48-0.55,1.176-0.55,2.085v0.249c0,0.886,0.182,1.567,0.547,2.046
		S291.844,120.144,292.522,120.144"
            />
            <path
              fill="#E8583F"
              d="M298.411,113.387v8.576c0,1.477-0.67,2.217-2.011,2.217c-0.288,0-0.556-0.043-0.802-0.128v-1.045
		c0.152,0.038,0.35,0.057,0.596,0.057c0.294,0,0.519-0.08,0.672-0.237c0.154-0.159,0.231-0.436,0.231-0.829v-8.61H298.411z
		 M296.962,111.348c0-0.208,0.064-0.387,0.194-0.536c0.131-0.149,0.321-0.224,0.572-0.224c0.256,0,0.45,0.073,0.583,0.22
		c0.132,0.147,0.199,0.327,0.199,0.54s-0.067,0.392-0.199,0.533c-0.133,0.142-0.327,0.213-0.583,0.213
		c-0.255,0-0.447-0.071-0.575-0.213S296.962,111.561,296.962,111.348"
            />
            <path
              fill="#E8583F"
              d="M305.274,121.074c-0.075-0.15-0.138-0.421-0.184-0.81c-0.612,0.635-1.341,0.952-2.189,0.952
		c-0.758,0-1.379-0.214-1.864-0.644c-0.485-0.429-0.729-0.972-0.729-1.63c0-0.801,0.305-1.423,0.913-1.866
		c0.608-0.442,1.464-0.664,2.568-0.664h1.278v-0.604c0-0.459-0.138-0.825-0.411-1.097c-0.275-0.273-0.681-0.409-1.216-0.409
		c-0.469,0-0.861,0.119-1.18,0.354c-0.316,0.237-0.475,0.524-0.475,0.86h-1.322c0-0.383,0.136-0.754,0.409-1.112
		c0.271-0.356,0.641-0.641,1.107-0.849s0.979-0.313,1.538-0.313c0.887,0,1.58,0.223,2.082,0.665
		c0.502,0.443,0.764,1.054,0.782,1.829v3.539c0,0.705,0.089,1.268,0.271,1.683v0.114H305.274z M303.093,120.072
		c0.412,0,0.803-0.105,1.172-0.318c0.37-0.215,0.637-0.491,0.803-0.832v-1.577h-1.029c-1.611,0-2.416,0.471-2.416,1.414
		c0,0.412,0.138,0.733,0.412,0.966C302.309,119.956,302.663,120.072,303.093,120.072"
            />
            <path
              fill="#E8583F"
              d="M308.471,120.386c0-0.229,0.068-0.417,0.203-0.568c0.134-0.152,0.337-0.228,0.606-0.228
		c0.271,0,0.476,0.075,0.615,0.228c0.14,0.151,0.209,0.34,0.209,0.568c0,0.218-0.069,0.399-0.209,0.547
		c-0.14,0.146-0.345,0.22-0.615,0.22c-0.27,0-0.473-0.073-0.606-0.22C308.54,120.785,308.471,120.604,308.471,120.386"
            />
            <polygon
              fill="#E8583F"
              points="318.709,116.265 317.445,117.578 317.445,121.074 316.081,121.074 316.081,110.729 
		317.445,110.729 317.445,115.846 322.042,110.729 323.69,110.729 319.619,115.299 324.009,121.074 322.375,121.074 	"
            />
            <rect
              x="325.111"
              y="110.161"
              fill="#E8583F"
              width="1.314"
              height="10.913"
            />
            <path
              fill="#E8583F"
              d="M328.537,111.348c0-0.213,0.064-0.393,0.194-0.54c0.13-0.146,0.323-0.22,0.579-0.22
		c0.255,0,0.45,0.073,0.583,0.22c0.132,0.147,0.198,0.327,0.198,0.54s-0.066,0.392-0.198,0.533
		c-0.133,0.142-0.328,0.213-0.583,0.213c-0.256,0-0.449-0.071-0.579-0.213S328.537,111.561,328.537,111.348 M329.957,121.074h-1.314
		v-7.688h1.314V121.074z"
            />
            <path
              fill="#E8583F"
              d="M333.296,113.387l0.035,0.854c0.563-0.664,1.323-0.995,2.28-0.995c1.075,0,1.808,0.412,2.196,1.235
		c0.255-0.368,0.588-0.668,0.998-0.895c0.409-0.227,0.894-0.341,1.453-0.341c1.686,0,2.543,0.893,2.571,2.679v5.15h-1.314v-5.072
		c0-0.55-0.125-0.961-0.376-1.233c-0.251-0.271-0.673-0.407-1.265-0.407c-0.488,0-0.894,0.145-1.216,0.437
		c-0.321,0.291-0.509,0.683-0.561,1.175v5.102h-1.322v-5.037c0-1.118-0.547-1.676-1.641-1.676c-0.862,0-1.452,0.366-1.77,1.101
		v5.612h-1.313v-7.688H333.296z"
            />
            <path
              fill="#E8583F"
              d="M349.558,121.074c-0.075-0.15-0.138-0.421-0.184-0.81c-0.612,0.635-1.341,0.952-2.189,0.952
		c-0.758,0-1.379-0.214-1.864-0.644c-0.485-0.429-0.729-0.972-0.729-1.63c0-0.801,0.305-1.423,0.913-1.866
		c0.608-0.442,1.464-0.664,2.568-0.664h1.278v-0.604c0-0.459-0.138-0.825-0.411-1.097c-0.275-0.273-0.681-0.409-1.216-0.409
		c-0.469,0-0.861,0.119-1.18,0.354c-0.317,0.237-0.475,0.524-0.475,0.86h-1.322c0-0.383,0.136-0.754,0.409-1.112
		c0.271-0.356,0.641-0.641,1.107-0.849s0.979-0.313,1.538-0.313c0.887,0,1.58,0.223,2.082,0.665
		c0.502,0.443,0.764,1.054,0.782,1.829v3.539c0,0.705,0.089,1.268,0.271,1.683v0.114H349.558z M347.377,120.072
		c0.412,0,0.803-0.105,1.172-0.318c0.37-0.215,0.638-0.491,0.803-0.832v-1.577h-1.029c-1.611,0-2.416,0.471-2.416,1.414
		c0,0.412,0.138,0.733,0.412,0.966C346.593,119.956,346.947,120.072,347.377,120.072"
            />
            <path
              fill="#E8583F"
              d="M354.51,111.525v1.861h1.437v1.016h-1.437v4.774c0,0.308,0.064,0.539,0.192,0.693s0.346,0.231,0.653,0.231
		c0.151,0,0.36-0.029,0.625-0.086v1.06c-0.346,0.095-0.682,0.142-1.009,0.142c-0.587,0-1.03-0.178-1.329-0.533
		c-0.298-0.354-0.446-0.86-0.446-1.513v-4.769h-1.4v-1.016h1.4v-1.861H354.51z"
            />
            <path
              fill="#E8583F"
              d="M359.996,119.149l1.791-5.763h1.405l-3.091,8.874c-0.478,1.279-1.238,1.918-2.28,1.918l-0.249-0.021
		l-0.489-0.092V123l0.354,0.028c0.445,0,0.792-0.09,1.041-0.271c0.249-0.18,0.454-0.509,0.615-0.986l0.291-0.782l-2.743-7.603h1.437
		L359.996,119.149z"
            />
            <polygon
              fill="#E8583F"
              points="365.601,120.002 370.098,120.002 370.098,121.074 363.996,121.074 363.996,120.107 
		368.237,114.474 364.059,114.474 364.059,113.387 369.885,113.387 369.885,114.317 	"
            />
            <path
              fill="#E8583F"
              d="M376.322,121.074c-0.075-0.15-0.138-0.421-0.184-0.81c-0.612,0.635-1.341,0.952-2.189,0.952
		c-0.758,0-1.379-0.214-1.864-0.644c-0.485-0.429-0.729-0.972-0.729-1.63c0-0.801,0.305-1.423,0.913-1.866
		c0.608-0.442,1.464-0.664,2.568-0.664h1.279v-0.604c0-0.459-0.139-0.825-0.412-1.097c-0.275-0.273-0.681-0.409-1.216-0.409
		c-0.469,0-0.861,0.119-1.18,0.354c-0.316,0.237-0.475,0.524-0.475,0.86h-1.322c0-0.383,0.136-0.754,0.409-1.112
		c0.271-0.356,0.641-0.641,1.107-0.849s0.979-0.313,1.538-0.313c0.887,0,1.58,0.223,2.082,0.665
		c0.502,0.443,0.764,1.054,0.782,1.829v3.539c0,0.705,0.089,1.268,0.271,1.683v0.114H376.322z M374.141,120.072
		c0.412,0,0.803-0.105,1.172-0.318c0.37-0.215,0.638-0.491,0.804-0.832v-1.577h-1.03c-1.611,0-2.416,0.471-2.416,1.414
		c0,0.412,0.138,0.733,0.412,0.966C373.357,119.956,373.71,120.072,374.141,120.072"
            />
            <path
              fill="#E8583F"
              d="M382.575,120.144c0.469,0,0.878-0.142,1.229-0.426c0.352-0.284,0.545-0.64,0.583-1.066h1.243
		c-0.024,0.44-0.175,0.86-0.454,1.259c-0.28,0.397-0.653,0.715-1.12,0.95c-0.466,0.237-0.96,0.356-1.48,0.356
		c-1.048,0-1.88-0.349-2.497-1.048c-0.618-0.699-0.928-1.654-0.928-2.867v-0.22c0-0.749,0.138-1.414,0.412-1.997
		c0.274-0.582,0.669-1.035,1.183-1.357c0.515-0.322,1.122-0.482,1.822-0.482c0.862,0,1.578,0.258,2.149,0.773
		c0.571,0.518,0.875,1.188,0.913,2.011h-1.243c-0.038-0.496-0.227-0.905-0.564-1.225c-0.34-0.32-0.758-0.479-1.255-0.479
		c-0.668,0-1.185,0.239-1.553,0.721c-0.367,0.48-0.55,1.176-0.55,2.085v0.249c0,0.886,0.182,1.567,0.547,2.046
		S381.897,120.144,382.575,120.144"
            />
            <path
              fill="#E8583F"
              d="M388.463,113.387v8.576c0,1.477-0.67,2.217-2.011,2.217c-0.288,0-0.556-0.043-0.802-0.128v-1.045
		c0.151,0.038,0.35,0.057,0.596,0.057c0.294,0,0.519-0.08,0.672-0.237c0.154-0.159,0.231-0.436,0.231-0.829v-8.61H388.463z
		 M387.015,111.348c0-0.208,0.064-0.387,0.194-0.536c0.131-0.149,0.321-0.224,0.572-0.224c0.256,0,0.45,0.073,0.583,0.22
		c0.132,0.147,0.199,0.327,0.199,0.54s-0.067,0.392-0.199,0.533c-0.133,0.142-0.327,0.213-0.583,0.213
		c-0.255,0-0.447-0.071-0.575-0.213S387.015,111.561,387.015,111.348"
            />
            <path
              fill="#E8583F"
              d="M395.328,121.074c-0.076-0.15-0.139-0.421-0.185-0.81c-0.612,0.635-1.341,0.952-2.188,0.952
		c-0.759,0-1.38-0.214-1.865-0.644c-0.485-0.429-0.729-0.972-0.729-1.63c0-0.801,0.305-1.423,0.913-1.866
		c0.608-0.442,1.464-0.664,2.568-0.664h1.279v-0.604c0-0.459-0.139-0.825-0.412-1.097c-0.275-0.273-0.681-0.409-1.216-0.409
		c-0.469,0-0.861,0.119-1.18,0.354c-0.316,0.237-0.475,0.524-0.475,0.86h-1.322c0-0.383,0.136-0.754,0.409-1.112
		c0.271-0.356,0.641-0.641,1.107-0.849s0.979-0.313,1.538-0.313c0.887,0,1.58,0.223,2.082,0.665
		c0.502,0.443,0.764,1.054,0.782,1.829v3.539c0,0.705,0.089,1.268,0.271,1.683v0.114H395.328z M393.146,120.072
		c0.412,0,0.803-0.105,1.172-0.318c0.37-0.215,0.638-0.491,0.804-0.832v-1.577h-1.03c-1.611,0-2.416,0.471-2.416,1.414
		c0,0.412,0.138,0.733,0.412,0.966C392.362,119.956,392.715,120.072,393.146,120.072"
            />
            <path
              fill="#E8583F"
              d="M398.524,120.386c0-0.229,0.068-0.417,0.203-0.568c0.134-0.152,0.337-0.228,0.607-0.228
		c0.27,0,0.475,0.075,0.614,0.228c0.14,0.151,0.209,0.34,0.209,0.568c0,0.218-0.069,0.399-0.209,0.547
		c-0.14,0.146-0.345,0.22-0.614,0.22c-0.271,0-0.474-0.073-0.607-0.22C398.592,120.785,398.524,120.604,398.524,120.386"
            />
            <path
              fill="#E8583F"
              d="M407.498,117.024v4.05h-1.364v-10.345h3.815c1.132,0,2.019,0.289,2.661,0.867
		c0.641,0.578,0.963,1.343,0.963,2.295c0,1.004-0.314,1.776-0.942,2.319c-0.628,0.542-1.526,0.813-2.695,0.813H407.498z
		 M407.498,115.909h2.451c0.729,0,1.288-0.172,1.677-0.516c0.388-0.344,0.582-0.841,0.582-1.491c0-0.617-0.194-1.111-0.582-1.481
		c-0.389-0.369-0.921-0.562-1.599-0.575h-2.529V115.909z"
            />
            <path
              fill="#E8583F"
              d="M414.666,117.16c0-0.754,0.148-1.431,0.445-2.032c0.295-0.602,0.708-1.065,1.235-1.393
		c0.528-0.327,1.131-0.49,1.809-0.49c1.046,0,1.893,0.362,2.54,1.087c0.646,0.726,0.969,1.689,0.969,2.893v0.092
		c0,0.749-0.143,1.42-0.429,2.014c-0.287,0.594-0.697,1.058-1.229,1.389c-0.532,0.332-1.145,0.497-1.837,0.497
		c-1.042,0-1.887-0.361-2.532-1.087c-0.647-0.724-0.971-1.684-0.971-2.877V117.16z M415.988,117.316
		c0,0.853,0.197,1.537,0.593,2.053s0.925,0.775,1.588,0.775c0.668,0,1.198-0.263,1.592-0.786s0.59-1.256,0.59-2.198
		c0-0.844-0.2-1.527-0.601-2.05c-0.4-0.523-0.932-0.785-1.595-0.785c-0.649,0-1.172,0.258-1.57,0.774
		C416.187,115.615,415.988,116.355,415.988,117.316"
            />
            <path
              fill="#E8583F"
              d="M424.549,113.387l0.035,0.854c0.563-0.664,1.323-0.995,2.28-0.995c1.075,0,1.808,0.412,2.196,1.235
		c0.255-0.368,0.588-0.668,0.998-0.895c0.409-0.227,0.894-0.341,1.453-0.341c1.686,0,2.543,0.893,2.571,2.679v5.15h-1.314v-5.072
		c0-0.55-0.125-0.961-0.376-1.233c-0.251-0.271-0.673-0.407-1.265-0.407c-0.488,0-0.894,0.145-1.216,0.437
		c-0.321,0.291-0.509,0.683-0.561,1.175v5.102h-1.322v-5.037c0-1.118-0.547-1.676-1.641-1.676c-0.862,0-1.452,0.366-1.77,1.101
		v5.612h-1.313v-7.688H424.549z"
            />
            <path
              fill="#E8583F"
              d="M442.559,117.315c0,1.17-0.268,2.113-0.803,2.828c-0.534,0.715-1.26,1.073-2.174,1.073
		c-0.933,0-1.668-0.296-2.203-0.888v3.7h-1.313v-10.643h1.2l0.063,0.853c0.536-0.662,1.279-0.994,2.231-0.994
		c0.924,0,1.654,0.348,2.191,1.045c0.538,0.695,0.807,1.665,0.807,2.904V117.315z M441.245,117.167c0-0.867-0.185-1.551-0.554-2.054
		c-0.369-0.502-0.876-0.753-1.521-0.753c-0.796,0-1.393,0.353-1.791,1.059v3.673c0.394,0.701,0.995,1.052,1.806,1.052
		c0.63,0,1.131-0.25,1.502-0.749C441.059,118.895,441.245,118.151,441.245,117.167"
            />
            <path
              fill="#E8583F"
              d="M446.667,119.149l1.791-5.763h1.405l-3.091,8.874c-0.478,1.279-1.238,1.918-2.28,1.918l-0.249-0.021
		l-0.489-0.092V123l0.354,0.028c0.445,0,0.792-0.09,1.041-0.271c0.249-0.18,0.454-0.509,0.615-0.986l0.291-0.782l-2.743-7.603h1.437
		L446.667,119.149z"
            />
            <path
              fill="#E8583F"
              d="M457.721,120.144c0.469,0,0.878-0.142,1.229-0.426c0.351-0.284,0.545-0.64,0.582-1.066h1.243
		c-0.023,0.44-0.175,0.86-0.454,1.259c-0.279,0.397-0.652,0.715-1.119,0.95c-0.467,0.237-0.96,0.356-1.481,0.356
		c-1.047,0-1.879-0.349-2.497-1.048s-0.927-1.654-0.927-2.867v-0.22c0-0.749,0.137-1.414,0.412-1.997
		c0.273-0.582,0.669-1.035,1.182-1.357c0.515-0.322,1.122-0.482,1.823-0.482c0.862,0,1.578,0.258,2.148,0.773
		c0.571,0.518,0.875,1.188,0.913,2.011h-1.243c-0.037-0.496-0.227-0.905-0.564-1.225c-0.339-0.32-0.757-0.479-1.254-0.479
		c-0.668,0-1.186,0.239-1.553,0.721c-0.367,0.48-0.551,1.176-0.551,2.085v0.249c0,0.886,0.183,1.567,0.547,2.046
		C456.523,119.904,457.044,120.144,457.721,120.144"
            />
            <path
              fill="#E8583F"
              d="M462.261,111.348c0-0.213,0.064-0.393,0.195-0.54c0.13-0.146,0.323-0.22,0.58-0.22
		c0.255,0,0.448,0.073,0.581,0.22c0.133,0.147,0.199,0.327,0.199,0.54s-0.066,0.392-0.199,0.533s-0.326,0.213-0.581,0.213
		c-0.257,0-0.45-0.071-0.58-0.213C462.326,111.739,462.261,111.561,462.261,111.348 M463.682,121.074h-1.314v-7.688h1.314V121.074z"
            />
            <path
              fill="#E8583F"
              d="M468.975,121.217c-1.042,0-1.89-0.343-2.543-1.026c-0.654-0.685-0.981-1.601-0.981-2.746v-0.242
		c0-0.762,0.146-1.443,0.438-2.042c0.292-0.6,0.699-1.068,1.222-1.407c0.524-0.339,1.091-0.508,1.702-0.508
		c0.999,0,1.776,0.33,2.33,0.988c0.554,0.657,0.832,1.601,0.832,2.826v0.548h-5.208c0.019,0.758,0.24,1.369,0.664,1.837
		c0.424,0.466,0.963,0.699,1.616,0.699c0.464,0,0.856-0.095,1.18-0.283c0.322-0.19,0.604-0.441,0.845-0.755l0.804,0.627
		C471.23,120.721,470.264,121.217,468.975,121.217 M468.812,114.325c-0.531,0-0.976,0.192-1.336,0.579
		c-0.36,0.386-0.582,0.927-0.668,1.624h3.851v-0.101c-0.037-0.668-0.218-1.186-0.54-1.552
		C469.797,114.509,469.361,114.325,468.812,114.325"
            />
            <path
              fill="#E8583F"
              d="M479.987,117.315c0,1.17-0.267,2.113-0.802,2.828s-1.26,1.073-2.174,1.073
		c-0.934,0-1.668-0.296-2.203-0.888v3.7h-1.314v-10.643h1.2l0.064,0.853c0.535-0.662,1.278-0.994,2.23-0.994
		c0.924,0,1.655,0.348,2.192,1.045c0.538,0.695,0.806,1.665,0.806,2.904V117.315z M478.673,117.167c0-0.867-0.186-1.551-0.555-2.054
		c-0.369-0.502-0.876-0.753-1.52-0.753c-0.796,0-1.394,0.353-1.791,1.059v3.673c0.393,0.701,0.995,1.052,1.805,1.052
		c0.63,0,1.131-0.25,1.502-0.749C478.487,118.895,478.673,118.151,478.673,117.167"
            />
            <polygon
              fill="#E8583F"
              points="483.249,114.9 484.372,114.467 484.372,115.341 483.249,115.774 483.249,121.074 481.927,121.074 
		481.927,116.278 480.905,116.67 480.905,115.796 481.927,115.404 481.927,110.162 483.249,110.162 	"
            />
            <path
              fill="#E8583F"
              d="M490.332,121.074c-0.075-0.15-0.138-0.421-0.184-0.81c-0.612,0.635-1.341,0.952-2.189,0.952
		c-0.758,0-1.379-0.214-1.864-0.644c-0.485-0.429-0.729-0.972-0.729-1.63c0-0.801,0.304-1.423,0.913-1.866
		c0.608-0.442,1.464-0.664,2.568-0.664h1.278v-0.604c0-0.459-0.138-0.825-0.411-1.097c-0.275-0.273-0.681-0.409-1.216-0.409
		c-0.469,0-0.861,0.119-1.18,0.354c-0.317,0.237-0.475,0.524-0.475,0.86h-1.322c0-0.383,0.136-0.754,0.409-1.112
		c0.271-0.356,0.641-0.641,1.107-0.849s0.979-0.313,1.538-0.313c0.887,0,1.58,0.223,2.082,0.665
		c0.502,0.443,0.764,1.054,0.782,1.829v3.539c0,0.705,0.089,1.268,0.27,1.683v0.114H490.332z M488.151,120.072
		c0.412,0,0.803-0.105,1.172-0.318c0.37-0.215,0.637-0.491,0.803-0.832v-1.577h-1.029c-1.611,0-2.416,0.471-2.416,1.414
		c0,0.412,0.138,0.733,0.412,0.966C487.367,119.956,487.72,120.072,488.151,120.072"
            />
            <path
              fill="#E8583F"
              d="M493.529,120.386c0-0.229,0.068-0.417,0.203-0.568c0.134-0.152,0.337-0.228,0.607-0.228
		c0.27,0,0.475,0.075,0.614,0.228c0.14,0.151,0.209,0.34,0.209,0.568c0,0.218-0.069,0.399-0.209,0.547
		c-0.14,0.146-0.345,0.22-0.614,0.22c-0.271,0-0.474-0.073-0.607-0.22C493.597,120.785,493.529,120.604,493.529,120.386"
            />
          </g>
          <rect id="mask" fill="#d2e5f6" width="575" height="127" />
          <g id="logo">
            <g>
              <defs>
                <rect id="SVGID_1_" x="79.765" width="416.159" height="127" />
              </defs>
              <clipPath id="SVGID_2_">
                <use xlinkHref="#SVGID_1_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_2_)"
                fill="#0064A3"
                d="M207.184,63.709c0,35.186-28.523,63.709-63.709,63.709
			S79.766,98.896,79.766,63.709S108.289,0,143.475,0S207.184,28.523,207.184,63.709"
              />
              <path
                clipPath="url(#SVGID_2_)"
                fill="#174DA1"
                d="M205.318,63.849c0,34.378-27.869,62.249-62.248,62.249
			c-34.377,0-62.247-27.87-62.247-62.249c0-34.378,27.87-62.247,62.247-62.247C177.449,1.603,205.318,29.471,205.318,63.849"
              />
              <path
                clipPath="url(#SVGID_2_)"
                fill="#FFFFFF"
                d="M202.547,63.849c0,32.646-26.465,59.111-59.111,59.111
			c-32.647,0-59.112-26.465-59.112-59.111S110.79,4.738,143.437,4.738C176.083,4.738,202.547,31.203,202.547,63.849"
              />
              <path
                clipPath="url(#SVGID_2_)"
                fill="#FFCE57"
                d="M99.418,31.27h87.034c-9.917-13.202-25.701-21.745-43.48-21.757h-0.073
			C125.12,9.525,109.335,18.068,99.418,31.27"
              />
              <path
                clipPath="url(#SVGID_2_)"
                fill="#F8B050"
                d="M99.418,31.27c-4.751,6.326-8.156,13.719-9.788,21.757h106.61
			c-1.632-8.038-5.036-15.431-9.788-21.757H99.418z"
              />
              <path
                clipPath="url(#SVGID_2_)"
                fill="#F3924C"
                d="M196.24,74.786c0.71-3.498,1.084-7.118,1.088-10.825v-0.109
			c-0.004-3.707-0.378-7.326-1.088-10.825H89.631c-0.714,3.516-1.09,7.154-1.09,10.88s0.376,7.364,1.09,10.879H196.24z"
              />
              <path
                clipPath="url(#SVGID_2_)"
                fill="#ED7544"
                d="M196.24,74.786H89.631c1.631,8.038,5.036,15.432,9.787,21.757h87.034
			C191.204,90.218,194.608,82.824,196.24,74.786"
              />
              <path
                clipPath="url(#SVGID_2_)"
                fill="#E8583F"
                d="M186.453,96.543H99.419c9.92,13.207,25.711,21.752,43.499,21.758h0.036
			C160.742,118.295,176.533,109.75,186.453,96.543"
              />
            </g>
            <g>
              <defs>
                <path
                  id="SVGID_3_"
                  d="M80.823,63.849c0,34.378,27.869,62.248,62.247,62.248c34.378,0,62.248-27.869,62.248-62.248
				S177.448,1.603,143.07,1.603C108.692,1.603,80.823,29.471,80.823,63.849"
                />
              </defs>
              <clipPath id="SVGID_4_">
                <use xlinkHref="#SVGID_3_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_4_)"
                fill="#0064A3"
                d="M207.59,79.431c0.028-0.068,0.054-0.138,0.077-0.207
			c1.194-3.519-3.274-8.215-9.981-10.492l-1.081-0.367l-4.377,12.722l1.133,0.386C199.935,83.702,206.218,82.78,207.59,79.431"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#FFFFFF"
                d="M202.083,72.977c-0.29-0.155,2.075,2.769,1.342,4.776
			c-0.704,1.929-2.534,2.918-3.57,3.257c-1.035,0.34,4.877,0.377,5.833-1.402C206.981,77.2,205.694,74.904,202.083,72.977"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#0064A3"
                d="M182.408,88.913c0,0,2.52,15.788-3.354,24.038
			c-5.875,8.25,14.311,9.184,14.311,9.184s4.315-20.436,2.124-35.565C193.298,71.438,182.408,88.913,182.408,88.913"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#FFFFFF"
                d="M193.9,88.559c0,0,0.388,15.2-1.283,22.777l-1.67,7.576
			c0,0-6.711-0.776-8.049-1.956s-1.977-0.93-0.828-3.116c1.149-2.188,3.211-4.821,3.376-18.59c0.164-13.77,4.872-6.205,4.872-6.205
			L193.9,88.559z"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#0064A3"
                d="M203.906,78.816c0.083-0.224,0.164-0.447,0.241-0.674
			c3.877-11.421-2.238-23.821-13.658-27.698l-1.841-0.625L174.52,91.146l1.929,0.654C187.643,95.601,199.778,89.801,203.906,78.816"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#0064A3"
                d="M196.648,75.597c-3.877,11.42-13.745,18.395-22.04,15.579
			c-8.294-2.816-11.877-14.357-8-25.777s13.745-18.395,22.04-15.579S200.525,64.176,196.648,75.597"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#FFFFFF"
                d="M193.905,74.665c-3.403,10.026-11.543,16.327-18.181,14.073
			c-6.638-2.253-9.26-12.207-5.857-22.233c3.404-10.025,11.544-16.327,18.182-14.073C194.688,54.685,197.31,64.64,193.905,74.665"
              />
              <line
                clipPath="url(#SVGID_4_)"
                fill="#164F9F"
                x1="190.489"
                y1="50.444"
                x2="188.648"
                y2="49.819"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#0064A3"
                d="M182.514,64.372c-3.182-1.08-6.638,0.624-7.718,3.806
			c-1.081,3.182,0.623,6.638,3.806,7.718l0.513,0.176l3.937-11.517L182.514,64.372z"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#0064A3"
                d="M178.886,75.992c1.939,0.659,4.388-1.387,5.468-4.569
			c1.08-3.183,0.384-6.297-1.556-6.954l-0.312-0.106l-3.928,11.52L178.886,75.992z"
              />
              <polygon
                clipPath="url(#SVGID_4_)"
                fill="#0064A3"
                points="180.944,75.792 182.407,88.913 188.807,84.452 182.051,74.285 		"
              />
              <polygon
                clipPath="url(#SVGID_4_)"
                fill="#0064A3"
                points="182.237,65.943 189.338,50.746 195.545,58.563 183.016,68.178 		"
              />
              <polygon
                clipPath="url(#SVGID_4_)"
                fill="#0064A3"
                points="177.868,71.866 165.68,75.98 167.404,68.669 178.82,70.3 		"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#0064A3"
                d="M187.324,57.519c1.357,1.041,2.296,2.567,2.95,4.157
			c0.66,1.604,0.961,3.335,1.053,5.055c0.075,1.729-0.147,3.443-0.533,5.103c-0.195,0.829-0.493,1.63-0.756,2.431
			c-0.342,0.778-0.688,1.55-1.091,2.291c0.083-0.851,0.232-1.668,0.368-2.483c0.087-0.824,0.219-1.635,0.306-2.444
			c0.033-0.816,0.192-1.616,0.171-2.426c0.007-0.403,0.046-0.806,0.049-1.206l-0.037-1.203c-0.058-1.6-0.268-3.187-0.656-4.743
			C188.738,60.499,188.191,58.966,187.324,57.519"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#0064A3"
                d="M185.61,81.481c-0.794,1.056-1.93,1.858-3.196,2.353
			c-1.266,0.49-2.697,0.708-4.076,0.478c-1.371-0.229-2.686-0.764-3.765-1.577c-0.567-0.365-1.013-0.883-1.496-1.327
			c-0.422-0.514-0.816-1.045-1.161-1.598c0.59,0.317,1.107,0.687,1.639,1.021l0.822,0.454c0.27,0.151,0.522,0.325,0.811,0.43
			c0.281,0.12,0.556,0.244,0.823,0.379c0.272,0.128,0.565,0.193,0.844,0.293c0.141,0.044,0.278,0.099,0.417,0.148
			c0.142,0.038,0.287,0.061,0.43,0.092c0.287,0.054,0.566,0.156,0.857,0.175c1.152,0.192,2.332,0.124,3.512-0.103
			C183.254,82.46,184.424,82.039,185.61,81.481"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#0064A3"
                d="M171.447,70.781c-0.395-1.39-0.236-2.9,0.157-4.298
			c0.402-1.408,1.092-2.725,1.951-3.899c0.853-1.187,1.931-2.177,3.063-3.053c0.57-0.435,1.197-0.792,1.798-1.167
			c0.644-0.316,1.286-0.625,1.953-0.874c-0.486,0.535-1.004,1.003-1.509,1.477l-1.456,1.459c-0.441,0.522-0.96,0.96-1.364,1.502
			c-0.212,0.261-0.444,0.504-0.652,0.766l-0.606,0.796c-0.783,1.075-1.499,2.195-2.066,3.405
			C172.158,68.109,171.688,69.38,171.447,70.781"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#FFFFFF"
                d="M196.543,57.653c-0.574-0.419,3.148,9.292,0.314,17.327
			c-2.723,7.716-7.478,12.519-10.041,14.403c-2.562,1.885,10.933-1.387,14.144-8.679C205.31,70.83,203.688,62.876,196.543,57.653"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#FFFFFF"
                d="M184.708,51.759v1.531c0.875,0,1.731,0.14,2.542,0.416
			c6.113,2.083,8.542,11.302,5.415,20.549c-2.629,7.771-8.608,13.41-14.217,13.41c-0.876,0-1.732-0.14-2.543-0.417
			c-6.113-2.083-8.542-11.301-5.414-20.548c2.628-7.771,8.607-13.41,14.217-13.41V51.759 M184.708,51.759
			c-6.176,0-12.743,5.822-15.661,14.449c-3.404,10.063-0.553,20.132,6.368,22.49c0.987,0.336,2.004,0.498,3.033,0.498
			c6.175,0,12.743-5.822,15.661-14.449c3.403-10.063,0.552-20.132-6.368-22.49C186.752,51.921,185.736,51.759,184.708,51.759"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#0064A3"
                d="M133.606,36.126c2.479-0.488,7.192,0.865,9.648-1.875
			c2.456-2.74,7.319-1.855,6.538,2.135c-0.782,3.99-3.84,9.287-4.306,10.416C145.021,47.931,133.606,36.126,133.606,36.126"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#FFFFFF"
                d="M135.785,40.12c0,0,6.467-1.31,7.993-2.792
			c1.527-1.482,2.325-2.942,3.14-2.258c0.815,0.683,1.631,1.284-0.008,5.175c-1.639,3.89-6.679,7.413-6.679,7.413L135.785,40.12z"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#0064A3"
                d="M70.708,125.192c0,0-8.041-13.654-5.995-21.398
			c2.047-7.745,18.381-18.679,22.442-25.631c4.061-6.953,3.672-7.726,4.564-11.592c0.947-4.105,1.239-7.123,2.72-10.41
			c1.085-2.409,3.849-8.675,4.437-10.133c0.588-1.457-4.323-12.54-1.144-15.244c3.18-2.704,8.393,1.391,10.483,2.842
			c2.091,1.45,3.837-0.262,4.734-0.529c10.295-4.994,21.497,0.938,21.497,0.938c8.572,3.287,11.956,8.002,12.595,9.073
			c0.594,0.998-2.676,0.789,0.091,5.768c2.251,4.049,3.07,6.095,3.296,6.717c0.056,0.153,0.124,0.291,0.215,0.425
			c0.17,0.251,0.543,0.792,1.36,1.953c1.784,2.535-0.079,6.576-0.927,8.271c-0.849,1.696-0.057,2.894-0.057,2.894
			s2.087,3.733,13.646,21.984c10.506,16.589-1.401,51.509-1.258,50.731"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#FFFFFF"
                d="M67.166,110.42c-0.876-7.321,5.828-14.882,9.995-19.121
			c4.168-4.237,10.466-6.176,13.488-12.768s3.093-9.772,3.697-13.543c0.623-3.89,3.982-12.535,5.693-14.971
			c1.482-2.111,1.102-3.591,0.46-7.592s-1.994-8.821-0.431-10.039c1.564-1.217,6.993,2.757,6.993,2.757s3.188,2.724,6.269,0.722
			c3.08-2.003,7.377-2.85,15.547-0.872c7.679,1.86,13.559,6.185,15.674,8.191c2.012,1.91,2.263,3.494,2.48,3.486
			c0.011,0,0.824,1.592,1.32-2.237c0.043-0.331,0.309-2.357,1.17,0.228c0.884,2.653,1.103,4.431-1.178,6.207
			c-1.611,1.254,1.222,3.884,0.82,4.127c-0.136,0.082-0.18,0.256-0.101,0.394l0.764,1.346c0.024,0.041,0.054,0.075,0.095,0.101
			c0.424,0.27,3.019,2.043,2.283,4.605c-0.802,2.786-4.647,5.534-4.135,7.67c0.512,2.137,8.127,12.932,14.806,24.75
			c4.973,8.801,1.289,29.394,1.987,30.04"
              />
              <path
                clipPath="url(#SVGID_4_)"
                fill="#FFFFFF"
                d="M97.99,113.667c0.325-0.596,0.464-1.419,0.153-3.444
			c-0.311-2.025-1.733-2.961-2.311-3.442c-0.578-0.479,0.892-0.01-0.625-2.529c-1.273-2.114-5.207-2.765-6.437-2.917
			c-0.191-0.024-0.307-0.221-0.236-0.399c0.194-0.487,0.628-1.525,1.519-3.433c0.967-2.069,1.288-7.43,1.393-10.327
			c0.012-0.342-0.473-0.425-0.574-0.096c-0.424,1.379-1.028,3.579-1.695,6.733c-1.319,6.248-5.931,10.018-3.551,9.324
			c2.38-0.693,6.619,0.251,7.187,1.353c0.613,1.191,0.988,1.937-0.769,2.635c-2.895,1.149-7.933,0.38-5.128,1.62
			c2.113,0.933,5.999,0.138,7.83-0.037c0.326-0.032,1.248,0.166,1.595,0.802c0.449,0.825,0.979,3.014,0.165,3.611"
              />
            </g>
            <g>
              <defs>
                <path
                  id="SVGID_5_"
                  d="M100.061,32.374c-1.564,1.218-0.212,6.039,0.43,10.039c0.642,4.001,0.927,3.819-1.102,5.413
				c-2.028,1.594-4.783,15.75-5.407,19.64c-0.604,3.771-0.802,5.447-3.824,12.039c-1.269,2.771-3.199,4.729-5.32,6.385
				c6.797,17.947,21.654,31.935,40.151,37.54l38.839-0.878c3.21-1.136,6.299-2.525,9.243-4.148
				c-2.285-5.572-6.553-16.054-11.722-24.559c-7.05-11.601-12.118-23.123-12.63-25.261c-0.511-2.137,2.675-4.369,3.477-7.155
				c0.736-2.561-1.858-4.334-2.283-4.604c-0.04-0.026-0.071-0.06-0.095-0.101l-0.764-1.346c-0.078-0.138-0.035-0.313,0.101-0.395
				c0.402-0.242,1.324-0.839,2.934-2.093c2.281-1.777,4.84-4.149,3.956-6.801c-0.861-2.585-7.371-1.713-7.703-1.667
				c-0.01,0.002-0.019,0.002-0.029,0.003c-0.218,0.007-1.757,0.657-3.771-1.252c-2.114-2.006-7.993-6.332-15.673-8.191
				c-8.17-1.978-12.467-1.131-15.547,0.871c-3.081,2.002-6.269-0.722-6.269-0.722s-4.083-2.988-6.187-2.988
				C100.544,32.143,100.268,32.213,100.061,32.374"
                />
              </defs>
              <clipPath id="SVGID_6_">
                <use xlinkHref="#SVGID_5_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_6_)"
                fill="#D2E5F6"
                d="M118.465,118.599c-5.787-1.093-6.263,1.146-8.569-6.685
			c-2.307-7.831-6.438-8.627-8.363-12.082c-1.924-3.454-1.961-5.385-0.594-11.504s2.222-8.799-0.941-11.782
			c-3.164-2.982-2.411,0.32-2.015-4.576c0.395-4.896,0.695-7.84,1.72-10.639c0.816-2.227,3.549-10.094,3.231-12.656
			c-0.317-2.563-0.831-5.247-2.864-9.169c-2.034-3.921-1.872-7.568,0.025-7.982c1.897-0.415,8.288,4.431,9.569,4.174
			c10.722-2.15,9.738-5.004,1.918,3.612c-3.81,4.199-3.429,14.12-1.869,14.928c0.866,0.448,3.19,0.202,4.855-0.042
			c1.348-0.198,2.579-0.869,3.451-1.915c0.612-0.733,1.337-1.761,2.094-3.175c2.549-4.754,1.733-5.462,5.49-6.674
			c1.568-0.506,5.78-0.139,5.78-0.139c1.891,0.06,5.85,1.308,5.669,2.519c-0.402,2.696-1.613,0.907-3.925,2.939
			c-1.046,0.92-1.543,2.311-1.422,3.699c0.139,1.591-0.809,3.045-0.954,4.742c-0.578,6.732-7.156,6.044-4.735,9.908
			c0.529,0.845,7.042,8.226,10.116,9.435c3.076,1.209,7.14,0.605,8.522-1.483c1.383-2.089,3.598-4.204,3.598-4.204l7.516,18.504
			c2.416,5.95,3.55,12.339,3.33,18.757c-0.233,6.794-0.92,15.301-2.807,20.288C152.787,136.661,124.252,119.689,118.465,118.599"
              />
              <path
                clipPath="url(#SVGID_6_)"
                fill="#D2E5F6"
                d="M129.551,68.563c0,0,1.328-5.739,4.252-4.973
			c2.764,0.725,4.378,2.96,7.437,2.723c2.558-0.198,1.211-5.633,4.513-4.353c3.301,1.281,6.101,2.245,4.593,4.431
			c-1.507,2.186-3.923,7.006-5.844,8.479c-1.922,1.472-4.409,2.334-7.661,0.493S129.551,68.563,129.551,68.563"
              />
              <g opacity="0.21" clipPath="url(#SVGID_6_)">
                <g>
                  <defs>
                    <rect
                      id="SVGID_7_"
                      x="123.288"
                      y="63.815"
                      width="24.965"
                      height="16.56"
                    />
                  </defs>
                  <clipPath id="SVGID_8_">
                    <use xlinkHref="#SVGID_7_" overflow="visible" />
                  </clipPath>
                  <path
                    clipPath="url(#SVGID_8_)"
                    fill="#467CBF"
                    d="M148.252,69.846c0,0-2.044,9.71-7.909,10.408
					c-6.712,0.8-12.679-2.366-14.92-9.267c-2.335-7.193-2.131-7.171-2.131-7.171s3.547,5.164,8.113,8.602
					c4.566,3.438,4.661,4.285,8.153,3.777c3.491-0.51,4.866-0.246,6.877-3.46c2.011-3.215,0.945-3.365,0.945-3.365L148.252,69.846z"
                  />
                </g>
              </g>
            </g>
            <g>
              <defs>
                <path
                  id="SVGID_9_"
                  d="M80.823,63.849c0,34.378,27.869,62.248,62.247,62.248c34.378,0,62.248-27.869,62.248-62.248
				S177.448,1.603,143.07,1.603C108.692,1.603,80.823,29.471,80.823,63.849"
                />
              </defs>
              <clipPath id="SVGID_10_">
                <use xlinkHref="#SVGID_9_" overflow="visible" />
              </clipPath>
              <path
                clipPath="url(#SVGID_10_)"
                fill="#0064A3"
                d="M143.116,44.634l5.719,7.781c0.373,0.835,0.826,1.474,0.883,1.663
			l0.465,0.891l-0.956,0.713l-6.164-10.54C143.063,45.142,142.63,43.995,143.116,44.634"
              />
              <path
                clipPath="url(#SVGID_10_)"
                fill="#0064A3"
                d="M149.546,65.476c0,0-2.029,5.756-5.589,8.684
			c-2.897,2.381-7.116,1.123-14.406-5.597c-7.29-6.72-7.079-5.678-7.079-5.678s-0.497-0.101,0.453,1.029
			c0.95,1.128,9.38,10.843,13.923,12.575c4.616,1.76,7.112,1.942,12.712-7.253C150.294,66.836,149.546,65.476,149.546,65.476"
              />
              <path
                clipPath="url(#SVGID_10_)"
                fill="#0064A3"
                d="M151.773,57.497c-0.09-0.009,0.198-0.734-3.71-1.207
			c-3.909-0.472-6.087,0.416-6.198,2.611c-0.11,2.194,0.818,5.432,3.144,6.298c2.326,0.865,4.943,1.836,6.206-0.116
			c1.264-1.952,1.942-4.66,1.25-6.123L151.773,57.497z"
              />
              <path
                clipPath="url(#SVGID_10_)"
                fill="#0064A3"
                d="M106.064,40.229c0,0,2.583-0.552-0.555-3.567
			c-3.137-3.015-4.127-2.96-4.138-2.855c-0.011,0.105,2.502,3.002,2.713,5.283C104.295,41.372,106.064,40.229,106.064,40.229"
              />
              <path
                clipPath="url(#SVGID_10_)"
                fill="#174DA1"
                d="M154.221,45.67c0,0-2.597-0.38-3.581,0.573
			c-0.278,0.269,0.495,0.277,0.598,1.316c0.103,1.038,0.219,1.762,0.394,1.039c0.213-0.878,0.824-1.756,1.603-2.082
			C154.013,46.191,154.745,45.716,154.221,45.67"
              />
              <path
                clipPath="url(#SVGID_10_)"
                fill="#174DA1"
                d="M131.443,46.166c0,0-0.454-1.209-2.132-1.362
			c-1.679-0.153-3.543-0.374-3.543-0.374s0.929,1.284,2.131,1.605c1.2,0.32,2.711,0.915,3.15,0.647
			C131.489,46.413,131.443,46.166,131.443,46.166"
              />
              <path
                clipPath="url(#SVGID_10_)"
                fill="#174DA1"
                d="M149.069,48.24c0,0-1.983-0.685-2.091-0.371
			c-0.109,0.315-0.63,1.463-0.157,1.488C147.295,49.382,149.536,48.565,149.069,48.24"
              />
              <path
                clipPath="url(#SVGID_10_)"
                fill="#0064A3"
                d="M146.722,63.191c0,0-0.65,5.021-4.006,5.525
			c-2.549,0.385-4.237-0.222-5.498-1.3c-0.439-0.376-0.906-0.898-1.235-1.339c-0.508-0.68-0.909-1.311-0.838-0.34
			c0.233,3.13,5.446,4.558,7.951,4.213c2.531-0.347,4.588-1.567,4.943-5.63C148.393,60.258,146.722,63.191,146.722,63.191"
              />
              <path
                clipPath="url(#SVGID_10_)"
                fill="#0064A3"
                d="M147.775,65.935c-1.332,4.316,1.57,3.11,1.57,3.11s0.275,1.343-2.205,0.981
			c-2.443-0.354-1.008-4.697-1.002-4.753L147.775,65.935z"
              />
              <path
                clipPath="url(#SVGID_10_)"
                fill="#D2E5F6"
                d="M141.006,51.108c-0.139-0.299-2.512-6.502-5.627-7.779
			c-3.115-1.276-2.721,2.396-2.721,2.396s4.258,1.459,6.026,4.311C140.452,52.888,142.258,53.801,141.006,51.108"
              />
              <path
                clipPath="url(#SVGID_10_)"
                fill="#0064A3"
                d="M142.949,44.735l5.651,7.395c0,0,5.908,0.369,7.595-7.982
			c0.177-0.811,0.264-1.194,0.657-1.429c0.394-0.235,0.544-1.003,0.572-1.484c0.027-0.481,0.135-0.631-0.334-0.772
			c-0.469-0.142-8.173-0.747-8.828-0.654c-0.655,0.093-1.81,0.985-2.958,0.725c-1.147-0.259-5.713-0.463-5.713-0.463
			s-0.758,0.197-1.157,0.039c-0.398-0.157-3.336-0.927-7.172-1.055c-3.835-0.128-8.293,0.394-8.293,0.394l-0.254,2.41
			c0,0,1.124,0.056,1.147,1.372c0.022,1.316,0.303,8.491,4.764,9.03c4.461,0.539,9.189,0.074,11.064-5.251
			c0.425-1.191,0.846-4.311,1.256-4.264c0.41,0.046,1.716,0.114,1.716,0.114s0.274-0.016,0.23,0.229
			C142.83,43.431,142.782,44.516,142.949,44.735"
              />
              <path
                clipPath="url(#SVGID_10_)"
                fill="#0064A3"
                d="M123.138,39.615c0,0-11.099-5.967-11.645-5.902
			c-0.546,0.065-1.699,0.965-1.699,0.965s2.51,0.299,4.545,1.58c2.035,1.279,8.36,5.588,8.36,5.588l1.897-0.568L123.138,39.615z"
              />
              <g opacity="0.21" clipPath="url(#SVGID_10_)">
                <g>
                  <defs>
                    <rect
                      id="SVGID_11_"
                      x="125.708"
                      y="40.722"
                      width="13.24"
                      height="10.522"
                    />
                  </defs>
                  <clipPath id="SVGID_12_">
                    <use xlinkHref="#SVGID_11_" overflow="visible" />
                  </clipPath>
                  <path
                    clipPath="url(#SVGID_12_)"
                    fill="#467CBF"
                    d="M138.774,45.39c0.018-0.104,1.189-4.181-2.813-4.499
					c-4.002-0.317-9.72-0.545-10.112,2.784s0.058,4.978,0.999,6.117c0.941,1.14,2.155,1.455,4.299,1.452
					C133.291,51.241,137.826,50.845,138.774,45.39"
                  />
                </g>
              </g>
            </g>
            <g>
              <defs>
                <path
                  id="SVGID_13_"
                  d="M125.85,43.675c-0.393,3.329,0.057,4.978,0.998,6.117c0.941,1.14,2.156,1.455,4.299,1.451
				c2.143-0.003,6.679-0.399,7.627-5.853c0.018-0.103,1.19-4.181-2.813-4.499c-1.111-0.088-2.356-0.169-3.581-0.169
				C129.193,40.722,126.133,41.271,125.85,43.675"
                />
              </defs>
              <clipPath id="SVGID_14_">
                <use xlinkHref="#SVGID_13_" overflow="visible" />
              </clipPath>

              <rect
                x="129.352"
                y="37.697"
                transform="matrix(0.957 0.2902 -0.2902 0.957 18.8526 -35.924)"
                clipPath="url(#SVGID_14_)"
                fill="#467CBF"
                width="2.377"
                height="15.801"
              />

              <rect
                x="133.176"
                y="38.099"
                transform="matrix(0.9568 0.2906 -0.2906 0.9568 19.1667 -37.0619)"
                clipPath="url(#SVGID_14_)"
                fill="#467CBF"
                width="2.378"
                height="15.802"
              />
            </g>
            <g>
              <defs>
                <path
                  id="SVGID_15_"
                  d="M80.823,63.849c0,34.378,27.869,62.248,62.247,62.248c34.378,0,62.248-27.869,62.248-62.248
				S177.448,1.603,143.07,1.603C108.692,1.603,80.823,29.471,80.823,63.849"
                />
              </defs>
              <clipPath id="SVGID_16_">
                <use xlinkHref="#SVGID_15_" overflow="visible" />
              </clipPath>
              <g opacity="0.21" clipPath="url(#SVGID_16_)">
                <g>
                  <defs>
                    <rect
                      id="SVGID_17_"
                      x="144.591"
                      y="41.673"
                      width="9.698"
                      height="9.24"
                    />
                  </defs>
                  <clipPath id="SVGID_18_">
                    <use xlinkHref="#SVGID_17_" overflow="visible" />
                  </clipPath>
                  <path
                    clipPath="url(#SVGID_18_)"
                    fill="#467CBF"
                    d="M144.862,46.961c0,0,2.934,3.82,2.899,3.828
					c-0.035,0.008,0.513,0.418,2.55-0.3c2.036-0.717,3.707-4.177,3.956-6.22c0.249-2.042-1.66-2.368-3.934-2.574
					c-2.274-0.205-4.087,1.036-4.888,1.736c-0.801,0.699-0.854,3.185-0.854,3.185L144.862,46.961z"
                  />
                </g>
              </g>
            </g>
            <g>
              <defs>
                <path
                  id="SVGID_19_"
                  d="M145.445,43.431c-0.801,0.699-0.854,3.185-0.854,3.185l0.271,0.345c0,0,2.934,3.82,2.9,3.828
				c-0.035,0.008,0.513,0.418,2.549-0.299c2.036-0.718,3.707-4.178,3.956-6.221c0.249-2.041-1.66-2.368-3.934-2.573
				c-0.171-0.016-0.339-0.023-0.504-0.023C147.793,41.673,146.186,42.783,145.445,43.431"
                />
              </defs>
              <clipPath id="SVGID_20_">
                <use xlinkHref="#SVGID_19_" overflow="visible" />
              </clipPath>

              <rect
                x="146.488"
                y="37.428"
                transform="matrix(0.9568 0.2907 -0.2907 0.9568 19.5564 -40.9747)"
                clipPath="url(#SVGID_20_)"
                fill="#467CBF"
                width="2.377"
                height="15.801"
              />

              <rect
                x="150.312"
                y="37.831"
                transform="matrix(0.9568 0.2907 -0.2907 0.9568 19.8388 -42.0691)"
                clipPath="url(#SVGID_20_)"
                fill="#467CBF"
                width="2.377"
                height="15.801"
              />
            </g>
            <path
              fill="#0064A3"
              d="M143.07,6.178c31.8,0,57.672,25.871,57.672,57.671c0,31.8-25.872,57.672-57.672,57.672
		c-31.8,0-57.671-25.872-57.671-57.672C85.399,32.049,111.271,6.178,143.07,6.178 M143.07,0.002
		c-34.378,0-63.247,29.469-63.247,63.847c0,35.077,28.869,63.569,63.247,63.569s64.113-29.189,64.113-63.569
		C207.184,29.471,179.466,0,143.07,0.002"
            />
            <path
              fill="#006EB4"
              d="M176.157,59.716c-1.619,0.203-3.229,0.146-4.831-0.118c-1.596-0.262-3.189-0.816-4.612-1.458
		c-1.424-0.635-2.761-1.26-4.181-1.854c-1.411-0.606-2.862-1.183-4.301-1.966c1.625-0.151,3.231-0.047,4.82,0.244
		c1.584,0.29,3.155,0.84,4.586,1.484c1.429,0.645,2.75,1.258,4.173,1.824C173.224,58.451,174.692,58.985,176.157,59.716"
            />
            <path
              fill="#006EB4"
              d="M174.214,64.344c-1.619,0.202-3.229,0.146-4.831-0.118c-1.596-0.262-3.189-0.816-4.612-1.458
		c-1.424-0.635-2.761-1.261-4.181-1.854c-1.411-0.606-2.862-1.183-4.301-1.967c1.625-0.15,3.231-0.046,4.82,0.245
		c1.584,0.289,3.155,0.84,4.586,1.484c1.429,0.645,2.75,1.257,4.173,1.824C171.282,63.079,172.749,63.613,174.214,64.344"
            />
            <path
              fill="#006EB4"
              d="M172.896,69.721c-1.619,0.203-3.229,0.146-4.831-0.118c-1.596-0.262-3.189-0.815-4.612-1.458
		c-1.424-0.635-2.761-1.261-4.181-1.854c-1.411-0.606-2.862-1.183-4.301-1.967c1.626-0.15,3.231-0.046,4.82,0.246
		c1.584,0.289,3.155,0.84,4.586,1.483c1.429,0.646,2.75,1.257,4.173,1.824C169.964,68.456,171.432,68.99,172.896,69.721"
            />
            <path
              fill="#006EB4"
              d="M171.279,75.83c-1.619,0.202-3.229,0.145-4.831-0.119c-1.596-0.261-3.189-0.815-4.612-1.457
		c-1.424-0.635-2.761-1.261-4.181-1.854c-1.411-0.605-2.862-1.183-4.301-1.967c1.625-0.149,3.231-0.046,4.82,0.244
		c1.584,0.29,3.155,0.841,4.586,1.485c1.429,0.645,2.75,1.257,4.173,1.824C168.346,74.564,169.814,75.099,171.279,75.83"
            />
          </g>
        </svg>
      </div>
    );
  }
}
